<template>
  <div class="about">
    <h1 class="title">Fulfilment Requests / Orders</h1>
    <div class="columns is-2">
      <div class="column">
        <div class="field">
          <label class="label">Search By</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="filterSearchType"
                v-on:change="searchByUpdateFilter"
              >
                <option value="General">Basic</option>
                <optgroup label="Advanced search">
                  <option value="$.meta_info.transaction_ref">
                    Transaction Ref
                  </option>
                  <option value="$.other.VendorTxCode">Vendor code</option>
                  <option value="$.meta_info.order_id">
                    Magazine Order Id
                  </option>
                  <option value="$.primary_contact.primary_contact_first_name">
                    First name
                  </option>
                  <option value="$.primary_contact.primary_contact_last_name">
                    Last name
                  </option>
                  <option value="$.products_info.offer_misc.publisher_code">
                    Publisher code
                  </option>
                  <option value="$.meta_info.campaign_ref">
                    Site Campaign Ref
                  </option>
                  <option value="$.products_info.offer_primary_fulfilment_code">
                    Primary Fulfilment Codes
                  </option>
                  <option
                    value="$.products_info.offer_secondary_fulfilment_code"
                  >
                    Secondary Fulfilment Codes
                  </option>
                </optgroup>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column is-3">
        <div class="field">
          <label class="label">Search</label>
          <div class="control">
            <div style="white-space: nowrap">
              <input
                class="input"
                type="search"
                :placeholder="GetQueryPlaceholder"
                v-model="query"
                v-on:keyup.enter="updateFilter"
                v-on:change="updateFilter"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Date Range</label>
          <v-date-picker
            mode="range"
            v-model="filterDateRange"
            disabled-attribute="disabled"
            :input-props="{
              readonly: true,
            }"
          ></v-date-picker>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Status</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="filterStatus" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option value="0">Unassigned</option>
                <option value="1">Assigned Batch</option>
                <option value="2">Send Success</option>
                <option value="3">Send Failed</option>
                <option value="4">Test or Cancelled</option>
                <option value="5">Data Transform Failed</option>
                <option value="6">Cancelled And Refunded</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Fraud</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="filterFraud" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option value="0">Unchecked</option>
                <option value="1">Flagged</option>
                <option value="2">Passed</option>
                <option value="3">Fraudulent</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Batch job Id</label>
          <div class="control">
            <input
              type="number"
              class="input is-fullwidth"
              v-model="filterBatchJobId"
              v-on:change="updateFilter"
            />
          </div>
        </div>
      </div>

      <div class="column" v-if="destinations">
        <div class="field">
          <label class="label">Destination</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select
                v-model="filterBatchDestinationId"
                v-on:change="updateFilter"
              >
                <option :value="null">All</option>
                <option v-for="d in destinations" :value="d.Id" :key="d.Id">
                  {{ d.Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Page size</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="size" v-on:change="updateFilter">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="notification is-warning" v-if="filterSearchType !== 'General'">
      You have selected an "Advanced Search" option, This option can take longer
      to return results, please be patience.
    </div>
    <p>
      <b-pagination
        v-if="Fulfilments"
        size="is-small"
        :total="Fulfilments.TotalRows"
        :current.sync="currentPaginationPage"
        :per-page="Fulfilments.Limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="paginatePage"
      ></b-pagination>
      <br />
    </p>
    <table
      class="table is-size-6 is-fullwidth"
      v-if="Fulfilments && Fulfilments.Results.length > 0 && !isLoading"
    >
      <thead>
        <tr>
          <th>
            <label class="checkbox-table">
              <input
                type="checkbox"
                :checked="
                  selectedFulfilmentRequests.length ==
                  Fulfilments.Results.length
                "
                v-on:change="selectAllClick"
              />
              <span></span>
            </label>
          </th>
          <th v-if="selectedFulfilmentRequests.length > 0" colspan="9">
            {{ selectedFulfilmentRequests.length }} orders selected ready for
            bulk action
          </th>

          <th
            v-if="selectedFulfilmentRequests.length > 0"
            colspan="2"
            class="has-text-vertical-middle has-text-right"
          >
            <b-dropdown aria-role="list" class="is-right">
              <button class="button is-primary is-small" slot="trigger">
                <span>Bulk Action</span>
                &nbsp;
                <i class="fas fa-caret-down"></i>
              </button>
              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="
                  cancelFulfilmentConfirmBulk(selectedFulfilmentRequests)
                "
                >Cancel</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="unassignConfirmBulk(selectedFulfilmentRequests)"
                >Mark As Unfulfilled</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="fulfilAsJobsConfirm(selectedFulfilmentRequests)"
                >Fulfil as Job(s)</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="showFraudulentDialog(true)"
                >Mark as fraudulent</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="showFraudulentDialog(false)"
                >Mark as not fraudulent</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="showBulkUpdateDialog"
                >Bulk Update Fulfilment</b-dropdown-item
              >
            </b-dropdown>
          </th>

          <th v-if="selectedFulfilmentRequests.length == 0">#</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Status</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Code</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Product</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Email</th>
          <th
            v-if="selectedFulfilmentRequests.length == 0"
            style="white-space: nowrap"
          >
            Postal Code
          </th>
          <th v-if="selectedFulfilmentRequests.length == 0">Fraud Check</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Method</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Price</th>
          <th v-if="selectedFulfilmentRequests.length == 0">Created</th>
          <th v-if="selectedFulfilmentRequests.length == 0"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="d in Fulfilments.Results"
          :key="d.Id"
          :class="{
            'is-highlighted': selectedFulfilmentRequests.indexOf(d) >= 0,
          }"
        >
          <td>
            <label class="checkbox-table">
              <input
                type="checkbox"
                v-model="selectedFulfilmentRequests"
                :value="d"
              />
              <span></span>
            </label>
          </td>
          <td>
            <router-link
              :to="{ name: 'View User Details', params: { id: d.Id } }"
              >{{ d.Id }}</router-link
            >
          </td>
          <td>
            <router-link
              class="tag"
              :class="getFulfilmentStatusClass(d)"
              v-if="isAssignedToBatchJob(d.Status)"
              :to="'batch-jobs?batchJobId=' + d.BatchJobId"
            >
              {{ getFulfilmentStatusString(d) }} ({{ d.BatchJobId }})
            </router-link>
            <span v-else class="tag" :class="getFulfilmentStatusClass(d)">
              {{ d.StatusString }}
            </span>
          </td>
          <td>
            <router-link
              v-if="hasFullAccess()"
              title="Manage this product code"
              :to="'product-config?search=' + d.FourLetterCode"
              >{{ d.FourLetterCode }}</router-link
            >
            <span v-else>{{ d.FourLetterCode }}</span>
          </td>
          <td>
            <router-link
              v-if="hasFullAccess()"
              title="Manage this product"
              :to="'product-config?search=' + d.FourLetterCode"
              >{{ d.ProductName }}</router-link
            >
            <span v-else>{{ d.ProductName }}</span>
          </td>
          <td>{{ d.Data.primary_contact.primary_contact_email }}</td>
          <td :title="getBillingAddress(d)" style="white-space: nowrap">
            {{ d.Data.billing_contact.billing_contact_postcode }}
          </td>
          <td>
            <span class="tag" :class="getFulfilmentFraudCheckClass(d)">
              {{ d.FraudCheckString }}
            </span>
          </td>
          <td>
            <img :src="getPaymentIcon(d)" />
          </td>
          <td>£{{ d.Data.products_info.offer_price }}</td>
          <td>{{ d.CreatedAt }}</td>

          <td class="mtd has-text-vertical-middle">
            <b-dropdown aria-role="list" class="is-right">
              <button class="button is-primary is-small" slot="trigger">
                <span>Options</span>
                &nbsp;
                <i class="fas fa-caret-down"></i>
              </button>

              <b-dropdown-item aria-role="listitem" has-link>
                <router-link
                  :to="{ name: 'View User Details', params: { id: d.Id } }"
                  >View Order</router-link
                >
              </b-dropdown-item>

              <b-dropdown-item
                aria-role="listitem"
                v-on:click="loadFulfilmentWithLogs(d.Id)"
                >View Logs</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="canResendFulfilment(d) && hasFullAccess()"
                v-on:click="unassignConfirm(d)"
                >Mark As Unfulfilled</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="showFraudulentDialog(true, d)"
                >Mark as fraudulent</b-dropdown-item
              >
              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="showFraudulentDialog(false, d)"
                >Mark as not fraudulent</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="hasFullAccess()"
                v-on:click="fulfilAsJobsConfirm([d])"
                >Fulfil as Job</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-on:click="resendOrderEmail(d)"
                >Resend Order Email</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="canCancelFulfilment(d) && hasFullAccess()"
                v-on:click="cancelFulfilmentConfirm(d)"
                >Cancel</b-dropdown-item
              >

              <b-dropdown-item
                aria-role="listitem"
                v-if="canDoCancelFlowFulfilment(d)"
                v-on:click="loadFulfilmentCancelFlow(d.Id)"
                >Cancel Flow</b-dropdown-item
              >

              <b-dropdown-item
                :disabled="!isRefundableOrdersSelected(d)"
                v-if="hasFullAccess()"
                aria-role="listitem"
                v-on:click="fulfilmentToRefund = d"
                >Refund Orders</b-dropdown-item
              > </b-dropdown
            >&nbsp;
            <button
              v-on:click="loadFulfilmentWithBatchJob(d.Id)"
              class="button is-primary is-small"
            >
              <i class="fas fa-info-circle"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else-if="isLoading">
      <a class="button is-large is-loading">Loading</a>
      <br />
      <br />
    </div>
    <div v-else>No Results found</div>
    <p>
      <b-pagination
        v-if="Fulfilments"
        size="is-small"
        :simple="false"
        :total="Fulfilments.TotalRows"
        :current.sync="currentPaginationPage"
        :per-page="Fulfilments.Limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="paginatePage"
      ></b-pagination>
    </p>

    <fulfilment-cancel-flow-dialog
      v-if="fulfilmentToCancel !== null"
      :fulfilment="fulfilmentToCancel"
      v-on:close="fulfilmentToCancel = null"
      v-on:fulfilmentUpdate="updateFulfilmentRecord"
    ></fulfilment-cancel-flow-dialog>

    <b-modal
      :active.sync="showFraudDialog"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="markingAsFraud">
            Fraudulent Orders - Confirmed
          </p>
          <p class="modal-card-title" v-else>Fraudulent Orders - Not Fraud</p>
        </header>
        <section class="modal-card-body">
          <span v-if="markingAsFraud"
            >These orders will be marked as fraudulent</span
          >
          <span v-else>
            These orders will be marked as
            <b>NOT</b> fraudulent, <br />this will mean they can then be
            fulfilled if they are not already.
          </span>
          <br />
          <br />
          <div v-if="markingAsFraud">
            <b-checkbox v-model="chkCancelOrdersAsWellAsFraud"
              >Mark orders as cancelled</b-checkbox
            >
            <p>This will ensure that the orders are never fulfilled</p>
          </div>
          <div v-if="ShowRefundOptionInFraudDialog">
            <br />
            <b-checkbox v-model="chkRefundOrdersAsWellAsFraud"
              >Refund Order</b-checkbox
            >
            <p>
              This will ensure that the
              {{ NumberOfPrePaidSelectedFulfilments }} selected prepaid orders
              are refunded from provider
            </p>
          </div>
          <div v-else-if="SelectedFulfilmentWhichCannotBeRefunded.length > 0">
            <br />
            <b>Unable to refund some of the selected orders</b>
            <p>
              There are currently
              {{ SelectedFulfilmentWhichCannotBeRefunded.length }} orders
              selected which cannot be refund so the option is disabled.<br />
              If you remove these fulfilment from the selection then you can
              refund the rest of the orders. See below the problem fulfilments
              <br />
              <br />
              The reasons these are unable to refund can be either they are
              multi cart orders, or they are too old and have missing data to be
              able to perform a refund from here.
            </p>
          </div>

          <br />
          <span
            v-html="this.getFulfilmentTable(selectedFulfilmentRequests, true)"
          ></span>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="showFraudDialog = false">
            Cancel
          </button>

          <b-button
            v-if="markingAsFraud"
            class="button is-danger"
            :loading="isLoading"
            v-on:click="confirmFraudulentBulkDialog"
          >
            <span v-if="this.IsRefundingForFraudDialog"
              >Mark as Fraudulent ({{ selectedFulfilmentRequests.length }}) and
              Refund ({{ NumberOfPrePaidSelectedFulfilments }})
            </span>
            <span v-else
              >Mark as Fraudulent ({{
                selectedFulfilmentRequests.length
              }})</span
            >
          </b-button>
          <button
            class="button is-primary"
            v-else
            @click.prevent="confirmFraudulentBulkDialog"
          >
            Mark as Valid
          </button>
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active.sync="showFraudCompletionDialog"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div
        class="modal-card"
        style="width: auto"
        v-if="fraudRefundResults != null"
      >
        <header class="modal-card-head">
          <p class="modal-card-title">Fraudulent Orders Refunds Results</p>
        </header>
        <section class="modal-card-body">
          <p>
            Below is a list of the fulfilment that have been marked as fraud.
            The status of their refund and adding to google sheet
          </p>
          <table class="table">
            <tr>
              <th>Id</th>
              <th>Code</th>
              <th>Email</th>
              <th>Can refund</th>
              <th>Success</th>
              <th>Error</th>
              <th>Logs</th>
            </tr>

            <tr v-for="f in fraudRefundResults" :key="f.FulfilmentRequest.Id">
              <td>{{ f.FulfilmentRequest.Id }}</td>
              <td>{{ f.FulfilmentRequest.FourLetterCode }}</td>
              <td>
                {{
                  f.FulfilmentRequest.DataObject.primary_contact
                    .primary_contact_email
                }}
              </td>
              <td>
                {{ fulfilmentCanRefundText(f.FulfilmentRequest) }}
              </td>
              <td>
                <span v-if="f.Success" class="tag is-success">Yes</span>
                <span v-else class="tag is-danger">No</span>
              </td>
              <td>{{ f.Message }}</td>
              <td>
                <a
                  class="button"
                  v-on:click="loadFulfilmentWithLogs(f.FulfilmentRequest.Id)"
                  >View</a
                >
              </td>
            </tr>
          </table>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="showFraudCompletionDialog = false"
          >
            Close
          </button>
        </footer>
      </div>
    </b-modal>

    <fulfilment-refund-dialog
      v-if="fulfilmentToRefund !== null"
      :fulfilment="fulfilmentToRefund"
      v-on:close="fulfilmentToRefund = null"
      v-on:fulfilmentUpdate="updateFulfilmentRecord"
    ></fulfilment-refund-dialog>

    <b-modal
      v-if="quickInfoDialogFulfilment"
      :active="quickInfoDialogFulfilment != null"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">
            Order Details of {{ quickInfoDialogFulfilment.Id }}
          </p>
          <button
            class="delete"
            aria-label="close"
            v-on:click="quickInfoDialogFulfilment = null"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="tile content">
            <div class="tile is-4 is-parent">
              <div class="tile is-child box">
                <order-general-info
                  :fulfilment="quickInfoDialogFulfilment"
                ></order-general-info>
              </div>
            </div>
            <div class="tile is-4 is-parent">
              <div class="tile is-child box">
                <order-fulfilment-info
                  :fulfilment="quickInfoDialogFulfilment"
                ></order-fulfilment-info>
              </div>
            </div>
            <div class="tile is-4 is-parent">
              <div class="tile is-child box">
                <order-finanical-info
                  :fulfilment="quickInfoDialogFulfilment"
                ></order-finanical-info>
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button"
            type="button"
            @click="quickInfoDialogFulfilment = null"
          >
            Close
          </button>
          <router-link
            class="button"
            :to="{
              name: 'View User Details',
              params: { id: quickInfoDialogFulfilment.Id },
            }"
            >Go to Order Page</router-link
          >
        </footer>
      </div>
    </b-modal>

    <fulfilment-log-dialog
      v-if="fulfilmentDetail !== null"
      :fulfilment="fulfilmentDetail"
      v-on:close="fulfilmentDetail = null"
    ></fulfilment-log-dialog>

    <fulfilment-bulk-update-dialog
      :fulfilments.sync="selectedFulfilmentRequests"
      v-if="bulkUpdateFulfilment"
      v-on:close="bulkUpdateFulfilment = false"
    >
    </fulfilment-bulk-update-dialog>
  </div>
</template>

<script>
import { baseMixin } from "../../mixins/baseMixin.js";

export default {
  components: {
    fulfilmentLogDialog: () =>
      import("../components/organisms/fulfilmentLogDialog.vue"),
    fulfilmentCancelFlowDialog: () =>
      import("../components/organisms/fulfilmentCancelFlowDialog.vue"),
    fulfilmentRefundDialog: () =>
      import("../components/organisms/fulfilmentRefundDialog.vue"),
    orderFulfilmentInfo: () =>
      import("../components/organisms/orderFulfilmentInfo.vue"),
    orderFinanicalInfo: () =>
      import("../components/organisms/orderFinanicalInfo.vue"),
    orderGeneralInfo: () =>
      import("../components/organisms/orderGeneralInfo.vue"),
    fulfilmentBulkUpdateDialog: () =>
      import("../components/organisms/fulfilmentBulkUpdateDialog.vue"),
  },
  mixins: [baseMixin],
  data: function () {
    var today = new Date();
    var priorDate = new Date(new Date().setDate(new Date().getDate() - 7));

    return {
      destinations: null,
      isLoading: false,
      APIUrl: process.env.VUE_APP_APIURL,
      Fulfilments: null,
      query: null,
      currentPaginationPage: 1,
      size: 25,
      filterBatchJobId: null,
      filterBatchDestinationId: null,
      filterStatus: null,
      filterFraud: null,
      filterSearchType: "General",
      filterDateRange: {
        start: priorDate,
        end: today,
      },
      fulfilmentDetail: null,
      fulfilmentToCancel: null,
      fulfilmentToRefund: null,
      selectedFulfilmentRequests: [],
      fraudRefundResults: null,
      showFraudDialog: false,
      showFraudCompletionDialog: false,
      markingAsFraud: false,
      chkCancelOrdersAsWellAsFraud: true,
      chkRefundOrdersAsWellAsFraud: true,
      showRefundDialog: false,
      quickInfoDialogFulfilment: null,
      refundReason: "",
      showAdvancedSearch: false,
      bulkUpdateFulfilment: false,
    };
  },
  watch: {
    // whenever question changes, this function will run
    filterDateRange: {
      deep: true,
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.updateFilter();
        }
      },
    },
  },
  created() {
    this.loadDestinationOptions();

    if (this.$route.query.batchJobId)
      this.filterBatchJobId = this.$route.query.batchJobId;
    if (this.$route.query.batchDestinationId)
      this.filterBatchDestinationId = this.$route.query.batchDestinationId;
    if (this.$route.query.status) this.filterStatus = this.$route.query.status;
    if (this.$route.query.fraudCheck)
      this.filterFraud = this.$route.query.fraudCheck;

    if (this.$route.query.size) this.size = this.$route.query.size;

    if (this.$route.query.query) this.query = this.$route.query.query;
    if (this.$route.query.searchType)
      this.filterSearchType = this.$route.query.searchType;

    if (this.$route.query.pageNumber)
      this.currentPaginationPage = this.$route.query.pageNumber;
    if (this.$route.query.dateFrom)
      this.filterDateRange.start = new Date(this.$route.query.dateFrom);
    if (this.$route.query.dateTo)
      this.filterDateRange.end = new Date(this.$route.query.dateTo);

    this.updateUrlAndLoadPage(true);

    window.onpopstate = this.loadFulfilments;
  },
  methods: {
    showFraudulentDialog(markingAsFraud, row) {
      if (row !== undefined) {
        this.selectedFulfilmentRequests = [row];
      }
      this.markingAsFraud = markingAsFraud;
      this.showFraudDialog = true;
    },

    showBulkUpdateDialog(row) {
      if (row !== undefined) {
        this.selectedFulfilmentRequests = [row];
      }
      this.bulkUpdateFulfilment = true;
    },

    confirmFraudulentBulkDialog() {
      var list = this.selectedFulfilmentRequests;
      var isFraud = this.markingAsFraud;

      this.isLoading = true;

      var IdQueryString = list
        .map(function (item) {
          return item.Id;
        })
        .join("&id=");

      var notes = ""; //not currently asked for
      var cancelAsWell = this.markingAsFraud
        ? this.chkCancelOrdersAsWellAsFraud
        : false;
      var fraudState = this.markingAsFraud ? 3 : 2; //3 is fraud, 2 is passed
      var url =
        this.APIUrl +
        "/fulfilment/bulk/fraudstate?notes=" +
        notes +
        "&id=" +
        IdQueryString +
        "&fraudState=" +
        fraudState +
        "&markFulfilmentAsCancelled=" +
        cancelAsWell;

      //if refund wanted as well, but only if the cancel option is also selected
      if (this.IsRefundingForFraudDialog) url += "&refundPayment=true";

      this.axios
        .put(url)
        .then((response) => {
          this.isLoading = false;

          response.data.forEach((element) => {
            element.FulfilmentRequest.Data =
              element.FulfilmentRequest.DataObject;
          });

          this.updateFulfilmentRecord(
            response.data.map((f) => f.FulfilmentRequest)
          );

          var msg =
            "Settings Fraudulent orders for " +
            this.selectedFulfilmentRequests.length +
            " orders as " +
            (isFraud ? "FRAUD" : "VALID");

          if (isFraud && this.IsRefundingForFraudDialog)
            msg +=
              " - Refunded " +
              this.NumberOfPrePaidSelectedFulfilments +
              " Orders";

          this.$toast.open(msg);

          //Open other dialog for refunds is it was selected
          if (this.IsRefundingForFraudDialog) {
            this.showFraudCompletionDialog = true;
            this.fraudRefundResults = response.data;
          }

          //clean up
          this.selectedFulfilmentRequests = [];
          this.showFraudDialog = false;
        })
        .catch(function (error) {
          this.isLoading = true;
          this.$toast.open(
            "oops something went wrong, I suggest reloading the page"
          );
          console.log(error);
        });
    },

    loadDestinationOptions() {
      var url = this.APIUrl + "/destination/all/basic";
      this.axios.get(url).then((response) => {
        this.destinations = response.data;
      });
    },
    updateUrlAndLoadPage(replace) {
      var url = "fulfilment?size=" + this.size;

      if (this.currentPaginationPage)
        url += "&pageNumber=" + this.currentPaginationPage;

      if (this.filterStatus) url += "&status=" + this.filterStatus;
      if (this.filterFraud) url += "&fraudCheck=" + this.filterFraud;

      if (this.filterBatchJobId) url += "&batchJobId=" + this.filterBatchJobId;

      if (this.filterBatchDestinationId)
        url += "&batchDestinationId=" + this.filterBatchDestinationId;

      if (this.query) url += "&query=" + this.query;
      if (this.filterSearchType) url += "&searchType=" + this.filterSearchType;

      if (this.filterDateRange.start)
        url +=
          "&dateFrom=" + this.getDateAsISOFormat(this.filterDateRange.start);
      if (this.filterDateRange.end)
        url += "&dateTo=" + this.getDateAsISOFormat(this.filterDateRange.end);

      if (replace) this.$router.replace(url);
      else this.$router.push(url);

      this.loadFulfilments();
    },
    searchByUpdateFilter() {
      this.query = "";
      this.updateFilter();
      // if (this.query !== null && this.query.length > 0) {
      //   //changing search by will only cause a update if we have query to search by
      //   this.updateFilter();
      // }
    },
    updateFilter() {
      this.currentPaginationPage = 1;
      this.updateUrlAndLoadPage(false);
    },

    paginatePage(page) {
      var pageInt = parseInt(page);
      if (pageInt <= 0) pageInt = 1;
      this.currentPaginationPage = pageInt;
      this.updateUrlAndLoadPage();
    },
    loadFulfilments() {
      this.isLoading = true;
      var url =
        this.APIUrl +
        "/fulfilment?take=" +
        this.size +
        "&pageNumber=" +
        (this.currentPaginationPage - 1);

      if (this.query) {
        if (this.filterSearchType == "General") {
          url += "&query=" + this.query;
        } else {
          url +=
            "&queryJsonRoute=" +
            encodeURIComponent(this.filterSearchType) +
            "&queryJsonValue=" +
            encodeURIComponent(this.query);
        }
      }

      if (this.filterStatus) url += "&status=" + this.filterStatus;
      if (this.filterFraud) url += "&fraudCheck=" + this.filterFraud;
      if (this.filterBatchJobId) url += "&batchJobId=" + this.filterBatchJobId;
      if (this.filterBatchDestinationId)
        url += "&batchDestinationId=" + this.filterBatchDestinationId;
      if (this.filterDateRange.start)
        url +=
          "&dateFrom=" + this.getDateAsISOFormat(this.filterDateRange.start);
      if (this.filterDateRange.end)
        url += "&dateTo=" + this.getDateAsISOFormat(this.filterDateRange.end);

      var that = this;
      this.axios.get(url).then((response) => {
        response.data.Results.forEach((element) => {
          element.Data = element.DataObject;
        });
        that.Fulfilments = response.data;
        this.isLoading = false;
      });
    },

    //Cancel orders
    cancelFulfilmentConfirmBulk(list) {
      var msg =
        "Are you sure you want to cancel (mark as a test) fulfilment order(s) <b>Count:" +
        list.length +
        "</b><br><br>" +
        this.getFulfilmentTable(list) +
        "<p><b>The fulfilment order(s) will be marked as cancelled/test and will never be sent as part of the fulfilment</b></p>" +
        "<br>Reason:";

      var title = "Cancel fulfilment";

      this.$dialog.prompt({
        title: title,
        message: msg,
        inputAttrs: {
          placeholder: "e.g. reason for this cancel",
          maxlength: 1000,
        },
        confirmText: "Prevent Fulfilment",
        type: "is-danger",
        onConfirm: (notes) => this.cancelFulfilmentBulk(list, notes),
      });
    },
    cancelFulfilmentConfirm(d) {
      var list = [d];
      this.cancelFulfilmentConfirmBulk(list);
    },
    cancelFulfilmentBulk(list, notes) {
      var IdQueryString = list
        .map(function (item) {
          return item.Id;
        })
        .join("&id=");

      var url =
        this.APIUrl +
        "/fulfilment/bulk/cancel?notes=" +
        notes +
        "&id=" +
        IdQueryString;

      this.axios.put(url).then((response) => {
        this.updateFulfilmentRecord(response.data);
        this.$toast.open(
          "Cancelled Fulfilment(s), it will be not be sent, Reason: " + notes
        );
      });
    },

    unassignFulfilmentBulk(list, notes) {
      var IdQueryString = list
        .map(function (item) {
          return item.Id;
        })
        .join("&id=");

      var url =
        this.APIUrl +
        "/fulfilment/bulk/unassign?notes=" +
        notes +
        "&id=" +
        IdQueryString;

      this.axios.put(url).then((response) => {
        this.updateFulfilmentRecord(response.data);

        this.$toast.open(
          "Unassigned Fulfilment(s), it will be resent in the next batch job, Reason: " +
            notes
        );
      });
    },
    //create mod job
    fulfilAsJobsConfirm(list) {
      var msg =
        "Are you sure you want to resend fulfilment order(s) <b> Count: " +
        list.length +
        "</b><br><br>" +
        this.getFulfilmentTable(list) +
        "<p><b>The fulfilment order(s) will be unassigned and new batch job(s) will be created and sent now</b></p>";
      //"<br>Reason:";

      var title = "Fulfil as job now";

      this.$dialog.confirm({
        //was prompt for the textbox
        title: title,
        message: msg,
        // inputAttrs: {
        //   placeholder: "e.g. reason for this resend",
        //   maxlength: 1000
        // },
        type: "is-danger",
        confirmText: "Send",
        onConfirm: (notes) => this.fulfilAsJobs(list, notes),
      });
    },

    fulfilAsJobs(list, notes) {
      var IdQueryString = list
        .map(function (item) {
          return item.Id;
        })
        .join("&id=");

      var fulfilmentIds = list.map(function (item) {
        return item.Id;
      });

      var url = this.APIUrl + "/destination/job/create?notes=" + notes;

      this.axios.post(url, fulfilmentIds).then((response) => {
        //this.updateFulfilmentRecord(response.data);
        var str = "";
        for (var i = 0; i < response.data.length; i++) {
          str +=
            response.data[i].Name +
            "(" +
            response.data[i].FulfilmentRequests.length +
            "), ";
        }
        this.selectedFulfilmentRequests = [];
        this.$toast.open(
          "Creating job(s) for given destinations: " +
            str +
            " with selected fulfilments for each"
        );
      });
    },

    //Unassign
    unassignConfirmBulk(list) {
      var msg =
        "Are you sure you want to mark these as unfulfilled<b> Count:" +
        list.length +
        "</b><br><br>" +
        this.getFulfilmentTable(list) +
        "<p><b>The fulfilment order(s) will be unassigned from its current batch job and will be then processed the next time its destination is sent</b></p>" +
        "<br>Reason:";

      var title = "Resend fulfilment";

      this.$dialog.prompt({
        title: title,
        message: msg,
        inputAttrs: {
          placeholder: "e.g. reason for this resend",
          maxlength: 1000,
        },
        type: "is-danger",
        confirmText: "Unassign and Resend",
        onConfirm: (notes) => this.unassignFulfilmentBulk(list, notes),
      });
    },
    unassignConfirm(d) {
      var list = [d];
      this.unassignConfirmBulk(list);
    },

    //Resend order emails
    // resendOrderEmailBulk(list) {
    // },
    resendOrderEmail(d) {
      var orderId = d.Data.meta_info.order_id;
      console.log(orderId);

      if (orderId == null || orderId == "" || orderId <= 0) {
        this.$toast.open({
          message:
            "Missing Order Id - Unable to resend confirmation email. Contact developer for help",
          type: "is-danger",
          duration: 5000,
        });
      } else {
        var msg =
          "Are you sure you want to resend the order confirmation email for Magazine Order Id <b>" +
          d.Data.meta_info.order_id +
          "</b>?<br><br>" +
          "If this order contained multipe items, the email will show these as well." +
          "<br><br>The email will go to <b>" +
          d.Data.primary_contact.primary_contact_email +
          "</b>.";

        var title = "Resend order confirmation email";

        console.log(d);

        this.$dialog.confirm({
          title: title,
          message: msg,
          type: "is-success",
          confirmText: "Send Email",
          onConfirm: (email) =>
            this.resendOrderEmailComplete(
              d,
              d.Data.primary_contact.primary_contact_email
            ),
        });
      }
    },

    resendOrderEmailComplete(d, email) {
      var url =
        this.APIUrl + "/fulfilment/" + d.Id + "/email/resend?email=" + email;

      this.axios.put(url).then((response) => {
        this.updateFulfilmentRecord(response.data);
        this.$toast.open(
          "Order confirmation email will  be resend shortly to " + email
        );
      });
    },

    //Helpers
    updateFulfilmentRecord(list) {
      var fulfilments = this.Fulfilments.Results;
      for (var i = 0; i < list.length; i++) {
        var f = list[i];
        f.Data = f.DataObject;
        this.$set(
          fulfilments,
          fulfilments.findIndex((x) => x.Id === f.Id),
          f
        );
      }
    },
    getFulfilmentTable(list, includeMultiCart) {
      var sb = includeMultiCart
        ? '<table class="table"><tr><th>Code</th><th>Email</th><th>Can Refund</th><th>Payment Method</th></tr>'
        : '<table class="table"><tr><th>Code</th><th>Email</th><th>Payment Method</th></tr>';

      for (var i = 0; i < list.length; i++) {
        var d = list[i];

        var paymentMethod = "";

        if (d.Data.products_info.payment_method == "DD") {
          paymentMethod = "Direct Debit";
        } else {
          paymentMethod = "Credit Card - " + d.Data.payment_info.CC.card_type;
        }

        sb += "<tr><td>" + d.FourLetterCode + "</td>";
        sb += "<td>" + d.Data.primary_contact.primary_contact_email + "</td>";
        if (includeMultiCart) {
          sb +=
            "<td title='" +
            this.fulfilmentCannotBeRefundedReason(d) +
            "'>" +
            this.fulfilmentCanRefundText(d) +
            "</td>";
        }

        sb += "<td>" + paymentMethod + "</td></<tr>";
      }
      sb += "</table>";

      return sb;
    },
    getPaymentIcon(d) {
      var filename = "";
      if (d.Data.products_info.payment_method == "DD") {
        filename = "directdebit";
      } else {
        switch (d.Data.payment_info.CC.card_type.toLowerCase()) {
          case "sagepay":
            filename = "sage";
            break;
          case "stripe":
            filename = "stripe";
            break;
          case "paypal":
            filename = "paypal";
            break;
          case "cdsfunnel":
            filename = "cds";
            break;
          default:
            filename = "missing";
            break;
        }
      }

      return "paymentmethods/" + filename + ".jpg";
    },
    canDoCancelFlowFulfilment(d) {
      return d.Status !== 4;
    },
    canCancelFulfilment(d) {
      return d.Status !== 2 && d.Status !== 4; //pointless marking as cancelled if its already been sent
    },
    canResendFulfilment(d) {
      return d.Status !== 0; //if unassigned, then pointless as it will just set to this status
    },
    getFulfilmentStatusClass(d) {
      switch (d.Status) {
        case 0: //unassigned
          return "is-warning";
        case 1: //assigned
        case 2: //send success
          return "is-success";
        case 3: //send failed
        case 4: //test/cancelled
        case 5: //data transform failed
        default:
          return "is-danger";
      }
    },
    getFulfilmentStatusString(d) {
      switch (d.Status) {
        case 0: //unassigned
          return "Unassigned";
        case 1: //assigned
          return "Assigned";
        case 2: //send success
          return "Send Success";
        case 3: //send failed
          return "Send Failed";
        case 4: //test/cancelled
          return "Cancelled";
        case 5: //data transform failed
          return "Failed Transform";
        default:
          return d.Status;
      }
    },
    getFulfilmentFraudCheckClass(d) {
      switch (d.FraudCheck) {
        case 0: //unchecked
          return "is-warning";
        case 1: //flagged
        case 3: //fraudulent
          return "is-danger";
        case 2: //passed
          return "is-success";
        default:
          return "is-danger";
      }
    },
    isAssignedToBatchJob(statusId) {
      switch (statusId) {
        case 1: //assigned
        case 2: //send success
        case 3: //send failed
          return true;
        default:
          return false;
      }
    },
    getBillingAddress(d) {
      return (
        "BILLING: " +
        d.Data.billing_contact.billing_contact_address1 +
        ", " +
        d.Data.billing_contact.billing_contact_address2 +
        ", " +
        d.Data.billing_contact.billing_contact_town +
        ", " +
        d.Data.billing_contact.billing_contact_county +
        ", " +
        d.Data.billing_contact.billing_contact_postcode +
        ", " +
        d.Data.billing_contact.billing_contact_country
      );
    },
    loadFulfilmentWithLogs(id) {
      var url = `${this.APIUrl}/fulfilment/${id}?includeLogs=true`;
      this.axios.get(url).then((response) => {
        this.fulfilmentDetail = response.data;
      });
    },
    loadFulfilmentWithBatchJob(id) {
      var url = `${this.APIUrl}/fulfilment/${id}?includeBatchInfo=true`;
      this.axios.get(url).then((response) => {
        this.quickInfoDialogFulfilment = response.data;
        this.quickInfoDialogFulfilment.Data = this.quickInfoDialogFulfilment.DataObject;
      });
    },
    loadFulfilmentCancelFlow(id) {
      var url = `${this.APIUrl}/fulfilment/${id}?includeBatchInfo=true&includeCustomerServiceContactInfo=true`;
      this.axios.get(url).then((response) => {
        this.fulfilmentToCancel = response.data;
        this.fulfilmentToCancel.Data = this.fulfilmentToCancel.DataObject;
      });
    },

    //fulfilmentToRefund

    // dateRangeUpdated(newRange) {
    //   console.log(newRange.start);
    //   console.log(newRange.end);

    //   this.filterDateRange.start = this.getSimpleDateFormat(newRange.start);
    //   this.filterDateRange.end = this.getSimpleDateFormat(newRange.end);
    //   this.updateFilter();
    // },
    getSimpleDateFormat(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      return year + "-" + month + "-" + dt;
    },

    selectAllClick: function () {
      if (
        this.selectedFulfilmentRequests.length ==
        this.Fulfilments.Results.length
      ) {
        this.selectedFulfilmentRequests = [];
      } else {
        this.selectedFulfilmentRequests = this.Fulfilments.Results;
      }
    },
    isRefundableOrdersSelected: function (list) {
      return (
        list.Data.products_info.payment_method == "CC" &&
        (list.Data.payment_info.CC.card_type === "SagePay" ||
          list.Data.payment_info.CC.card_type === "Stripe" ||
          list.Data.payment_info.CC.card_type === "Paypal")
      );
    },

    IsMultiCartJMagOrder: function (d) {
      return (
        d.Data.other.is_JMAG == "1" &&
        parseFloat(d.Data.products_info.offer_price) !==
          parseFloat(d.Data.other.subtotal)
      );
    },
  },
  computed: {
    GetQueryPlaceholder: function () {
      switch (this.filterSearchType) {
        case "$.meta_info.campaign_ref":
          return "e.g. JMAG, CAHK, HNUR, BJOM";
        case "$.primary_contact.primary_contact_first_name":
          return "Contacts first name";
        case "$.primary_contact.primary_contact_last_name":
          return "Contacts last name";
        case "$.products_info.offer_primary_fulfilment_code":
          return "Primary Fulfilment code";
        case "$.products_info.offer_secondary_fulfilment_code":
          return "Secondary Fulfilment code";
        case "$.other.VendorTxCode":
          return "Sage, Stripe or Paypal Order Id";
        case "$.meta_info.order_id":
          return "Magazine (Magento) Order Id";
        case "$.meta_info.transaction_ref":
          return "Transaction Reference";
        case "$.products_info.offer_misc.publisher_code":
          return "Publisher Code";
        default:
          return "Id, Email, Shortsite, postcode";
        // code block
      }
    },
    NumberOfPrePaidSelectedFulfilments: function () {
      return this.selectedFulfilmentRequests.filter(
        (r) => r.Data.products_info.payment_method === "CC"
      ).length;
    },
    SelectedFulfilmentWhichCannotBeRefunded: function () {
      var that = this;
      //Get any JMAG multi cart orders (as they cannot be refunded)
      return this.selectedFulfilmentRequests.filter(
        (r) => !that.fulfilmentCanBeRefundedFromFraud(r)
      );
    },
    ShowRefundOptionInFraudDialog: function () {
      return (
        this.markingAsFraud &&
        this.chkCancelOrdersAsWellAsFraud &&
        this.NumberOfPrePaidSelectedFulfilments > 0 &&
        this.SelectedFulfilmentWhichCannotBeRefunded.length === 0
      );
    },
    IsRefundingForFraudDialog: function () {
      return (
        this.ShowRefundOptionInFraudDialog && this.chkRefundOrdersAsWellAsFraud
      );
    },
  },
};
</script>
